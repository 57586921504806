<template>
  <div
    id="BxPlanosCompra"
    class="bx-planos-compra-bx-planos-compra"
    v-bind:class="rootClassName"
  >
    <div class="bx-planos-compra-frame11">
      <div class="bx_card_compra bx-planos-compra-card11">
        <div class="card_plano">
          <div class="bx-planos-compra-h21">
            <h3>
              <slot name="text21">
                <!--Default content for text21-->
                <div class="bx-planos-compra-fragment19">
                  <h3 class="bx-planos-compra-text43"><span>Gratuito</span></h3>
                </div>
              </slot>
            </h3>
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt15" :src="vectorSrc15" class="icon_plano" />
            <span>
              <slot name="text31">
                <!--Default content for text31-->
                <div class="bx-planos-compra-fragment13">
                  <span class="bx-planos-compra-text31 itens text_plano">
                    <span>Email</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt611" :src="vectorSrc611" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="unionAlt3" :src="unionSrc3" class="icon_plano" />
            <span>
              <slot name="text41">
                <!--Default content for text41-->
                <div class="bx-planos-compra-fragment25">
                  <span class="bx-planos-compra-text55 itens text_plano">
                    <span>WhatsApp</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt21" :src="vectorSrc21" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt31" :src="vectorSrc31" class="icon_plano" />
            <span>
              <slot name="text51">
                <!--Default content for text51-->
                <div class="bx-planos-compra-fragment12">
                  <span class="bx-planos-compra-text30 itens text_plano">
                    Ligação
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt21" :src="vectorSrc21" class="icon_plano" />
          </div>
        </div>
      </div>
      <div class="bx_card_compra bx-planos-compra-card21">
        <div class="bx-planos-compra-card22 card_plano">
          <div class="bx-planos-compra-h22">
            <h3>
              <slot name="text61">
                <!--Default content for text61-->
                <div class="bx-planos-compra-fragment14">
                  <h3 class="bx-planos-compra-text33"><span>Premium</span></h3>
                </div>
              </slot>
            </h3>
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt15" :src="vectorSrc15" class="icon_plano" />
            <span>
              <slot name="text71">
                <!--Default content for text71-->
                <div class="bx-planos-compra-fragment15">
                  <span class="bx-planos-compra-text35 itens text_plano">
                    <span>Email</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt611" :src="vectorSrc611" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="unionAlt3" :src="unionSrc3" class="icon_plano" />
            <span>
              <slot name="text81">
                <!--Default content for text81-->
                <div class="bx-planos-compra-fragment17">
                  <span class="bx-planos-compra-text39 itens text_plano">
                    <span>WhatsApp</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt611" :src="vectorSrc611" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt31" :src="vectorSrc31" class="icon_plano" />
            <span>
              <slot name="text91">
                <!--Default content for text91-->
                <div class="bx-planos-compra-fragment10">
                  <span class="bx-planos-compra-text26 itens text_plano">
                    <span>Ligação</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt21" :src="vectorSrc21" class="icon_plano" />
          </div>
          <div class="list_plano">
            <span>
              <slot name="text141">
                <!--Default content for text141-->
                <div class="bx-planos-compra-fragment11">
                  <span class="bx-planos-compra-text28 text_price">
                    <span>R$ 1,00 / Mês</span>
                  </span>
                </div>
              </slot>
            </span>
          </div>
        </div>
        <div class="bx-planos-compra-btcard1">
          <button
            id="ButtonComprar_1"
            name="ButtonComprar_1"
            onclick="comprar(1)"
            class="bx-planos-compra-button1"
          >
            <span>
              <slot name="text16">
                <!--Default content for text16-->
                <div class="bx-planos-compra-fragment16">
                  <span class="bx-planos-compra-text37 itens">
                    <span>Eu Quero!!</span>
                  </span>
                </div>
              </slot>
            </span>
          </button>
        </div>
      </div>
      <div class="bx_card_compra bx-planos-compra-card31" >
        <div class="card_plano bx-planos-compra-card32">
          <div class="bx-planos-compra-h23">
            <h3>
              <slot name="text101">
                <!--Default content for text101-->
                <div class="bx-planos-compra-fragment20">
                  <h3 class="bx-planos-compra-text45"><span>Plus</span></h3>
                </div>
              </slot>
            </h3>
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt15" :src="vectorSrc15" class="icon_plano" />
            <span>
              <slot name="text111">
                <!--Default content for text111-->
                <div class="bx-planos-compra-fragment23">
                  <span class="bx-planos-compra-text51 itens text_plano">
                    <span>Email</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt611" :src="vectorSrc611" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="unionAlt3" :src="unionSrc3" class="icon_plano" />
            <span>
              <slot name="text121">
                <!--Default content for text121-->
                <div class="bx-planos-compra-fragment22">
                  <span class="bx-planos-compra-text49 itens text_plano">
                    <span>WhatsApp</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt611" :src="vectorSrc611" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt31" :src="vectorSrc31" class="icon_plano" />
            <span>
              <slot name="text131">
                <!--Default content for text131-->
                <div class="bx-planos-compra-fragment18">
                  <span class="bx-planos-compra-text41 itens text_plano">
                    <span>Ligação</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt611" :src="vectorSrc611" class="icon_plano" />
          </div>
          <div class="list_plano">
            <span>
              <slot name="text151">
                <!--Default content for text151-->
                <div class="bx-planos-compra-fragment21">
                  <span class="bx-planos-compra-text47 text_price">
                    <span>R$ 50,00 / Mês</span>
                  </span>
                </div>
              </slot>
            </span>
          </div>
        </div>
        <div class="bx-planos-compra-btcard2">
          <button
            id="ButtonComprar_2"
            name="ButtonComprar_2"
            onclick="comprar(2)"
            class="bx-planos-compra-button2"
          >
            <span>
              <slot name="text17">
                <!--Default content for text17-->
                <div class="bx-planos-compra-fragment24">
                  <span class="bx-planos-compra-text53 itens">
                    <span>Eu Quero!!</span>
                  </span>
                </div>
              </slot>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BxPlanosCompra',
  props: {
    vectorSrc15: {
      type: String,
      default:
        '/static/image/email.svg',
    },
    vectorAlt15: {
      type: String,
      default: 'email',
    },

    vectorSrc31: {
      type: String,
      default:
        '/static/image/telefone.svg',
    },
    vectorAlt31: {
      type: String,
      default: 'telefone',
    },
    unionSrc3: {
      type: String,
      default:
        '/static/image/whtsapp.svg',
    },
    unionAlt3: {
      type: String,
      default: 'whtsapp',
    },    
    vectorSrc21: {
      type: String,
      default:
        '/static/image/x.svg',
    },
    vectorAlt21: {
      type: String,
      default: 'x',
    },
    vectorSrc611: {
      type: String,
      default:
        '/static/image/check.svg',
    },
    vectorAlt611: {
      type: String,
      default: 'check',
    },
  },
}
</script>

<style scoped>
.bx-planos-compra-bx-planos-compra {
  gap: 10px;
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  flex-direction: column;
  background-color: rgba(117, 174, 187, 0.30000001192092896);
  display: none;
}
.bx-planos-compra-frame11 {
  width: 100%;
  display: flex;
  align-items: center;
}
.bx-planos-compra-h21 {
  gap: 10px;
  width: 100%;
  height: 25%;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(9, 75, 91, 1);
}
.bx-planos-compra-card22 {
  background-color: rgba(71, 137, 152, 1);
}
.bx-planos-compra-h22 {
  gap: 10px;
  width: 100%;
  height: 25%;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(9, 75, 91, 1);
}
.bx-planos-compra-btcard1 {
  gap: 30px;
  display: flex;
  padding: 10px;
  margin-top: 10px;
  transition: 0.3s;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(9, 75, 91, 1);
}
.bx-planos-compra-btcard1:hover {
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  background-color: #0f7e96;
}
.bx-planos-compra-button1 {
  gap: 0;
  width: 100%;
  height: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
}
.bx-planos-compra-button1:focus {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.bx-planos-compra-button1:hover {
  background-color: #89ccda;
}
.bx-planos-compra-h23 {
  gap: 10px;
  width: 100%;
  height: 25%;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(9, 75, 91, 1);
}
.bx-planos-compra-btcard2 {
  gap: 30px;
  height: auto;
  display: flex;
  padding: 10px;
  margin-top: 10px;
  transition: 0.3s;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(9, 75, 91, 1);
}
.bx-planos-compra-btcard2:hover {
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  background-color: #0f7e96;
}
.bx-planos-compra-button2 {
  gap: 0;
  width: 100%;
  height: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
}
.bx-planos-compra-button2:focus {
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.bx-planos-compra-button2:hover {
  background-color: #89ccda;
}
.bx-planos-compra-fragment10 {
  display: contents;
}
.bx-planos-compra-text26 {
  display: inline-block;
}
.bx-planos-compra-fragment11 {
  display: contents;
}
.bx-planos-compra-text28 {
  display: inline-block;
}
.bx-planos-compra-fragment12 {
  display: contents;
}
.bx-planos-compra-text30 {
  display: inline-block;
}
.bx-planos-compra-fragment13 {
  display: contents;
}
.bx-planos-compra-text31 {
  display: inline-block;
}
.bx-planos-compra-fragment14 {
  display: contents;
}
.bx-planos-compra-text33 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.bx-planos-compra-fragment15 {
  display: contents;
}
.bx-planos-compra-text35 {
  display: inline-block;
}
.bx-planos-compra-fragment16 {
  display: contents;
}
.bx-planos-compra-text37 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  text-align: center;
  font-stretch: normal;
}
.bx-planos-compra-fragment17 {
  display: contents;
}
.bx-planos-compra-text39 {
  display: inline-block;
}
.bx-planos-compra-fragment18 {
  display: contents;
}
.bx-planos-compra-text41 {
  display: inline-block;
  font-stretch: normal;
}
.bx-planos-compra-fragment19 {
  display: contents;
}
.bx-planos-compra-text43 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  font-stretch: normal;
  text-decoration: none;
}
.bx-planos-compra-fragment20 {
  display: contents;
}
.bx-planos-compra-text45 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.bx-planos-compra-fragment21 {
  display: contents;
}
.bx-planos-compra-text47 {
  display: inline-block;
}
.bx-planos-compra-fragment22 {
  display: contents;
}
.bx-planos-compra-text49 {
  display: inline-block;
}
.bx-planos-compra-fragment23 {
  display: contents;
}
.bx-planos-compra-text51 {
  display: inline-block;
}
.bx-planos-compra-fragment24 {
  display: contents;
}
.bx-planos-compra-text53 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  text-align: center;
  font-stretch: normal;
}
.bx-planos-compra-fragment25 {
  display: contents;
}
.bx-planos-compra-text55 {
  display: inline-block;
}
.bx-planos-compraroot-class-name {
  display: none;
}
.bx-planos-compraroot-class-name1 {
  height: auto;
  display: none;
}
.bx-planos-compra-card31 {
  display: none;
}
@media(max-width: 1600px) {
  .bx-planos-compra-bx-planos-compra {
    display: none;
  }
}
@media(max-width: 767px) {
  .bx-planos-compra-text37 {
    font-size: large;
  }
  .bx-planos-compra-text53 {
    font-size: large;
  }
}
@media(max-width: 479px) {
  .bx-planos-compra-bx-planos-compra {
    display: none;
  }
  .bx-planos-compra-card11 {
    display: none;
  }
  .bx-planos-compra-card21 {
    width: 44vw;
    height: 100%;
  }
  .bx-planos-compra-card22 {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .bx-planos-compra-btcard1 {
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 10px;
  }
  .bx-planos-compra-card31 {
    width: 44vw;
    height: 100%;
  }
  .bx-planos-compra-card32 {
    width: 100%;
    height: auto;
  }
  .bx-planos-compra-btcard2 {
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 10px;
  }
  .bx-planos-compra-text37 {
    width: auto;
    height: 100%;
  }
  .bx-planos-compra-text47 {
    margin: auto;
  }
}
</style>
