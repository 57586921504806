<template>
  <div class="planos-container1">
    <div class="planos-planos">
      <app-menu rootClassName="menuroot-class-name1">
      </app-menu>
      <div class="planos-body">
        <div class="planos-h1">
          <span class="planos-text18"><span>Planos</span></span>
        </div>
        <bx-planos rootClassName="bx-planosroot-class-name">
        </bx-planos>
        <app-forms rootClassName="formsroot-class-name">
        </app-forms>
        <bx-planos-compra rootClassName="bx-planos-compraroot-class-name">
        </bx-planos-compra>
      </div>
      <app-footer rootClassName="footerroot-class-name3">
      </app-footer>
    </div>
    <div>
      <div class="planos-container3">
        <DangerousHTML
          html="<script>
  function st(){
      document.getElementById('BtPgplano').style.backgroundColor = '#4789985a';
      };
  st()
  </script>"
        ></DangerousHTML>
      </div>
    </div>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import AppMenu from '../components/menu'
import BxPlanos from '../components/bx-planos'
import AppForms from '../components/forms'
import BxPlanosCompra from '../components/bx-planos-compra'
import AppFooter from '../components/footer'

export default {
  name: 'Planos',
  components: {
    AppMenu,
    BxPlanos,
    AppForms,
    BxPlanosCompra,
    AppFooter,
    DangerousHTML,
  },
  data() {
    return {
      raw4d25: ' ',
    }
  },
  metaInfo: {
    title: 'exported project',
  },
}
</script>

<style scoped>
.planos-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.planos-planos {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 10px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.planos-fragment10 {
  display: contents;
}
.planos-text10 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.planos-fragment11 {
  display: contents;
}
.planos-text12 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.planos-fragment12 {
  display: contents;
}
.planos-text14 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.planos-fragment13 {
  display: contents;
}
.planos-text16 {
  color: rgba(9, 75, 91, 1);
  height: auto;
  display: inline-block;
  font-size: medium;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.planos-body {
  gap: 30px;
  width: 1076px;
  display: flex;
  padding: 20px 10px;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.planos-h1 {
  gap: 10px;
  display: flex;
  padding: 10px 44px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.planos-text18 {
  color: rgba(24, 97, 114, 1);
  height: auto;
  font-size: 24px;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.planos-fragment14 {
  display: contents;
}
.planos-text20 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  font-stretch: normal;
  text-decoration: none;
}
.planos-fragment15 {
  display: contents;
}
.planos-text22 {
  display: inline-block;
}
.planos-fragment16 {
  display: contents;
}
.planos-text24 {
  display: inline-block;
}
.planos-fragment17 {
  display: contents;
}
.planos-text26 {
  display: inline-block;
}
.planos-fragment18 {
  display: contents;
}
.planos-text27 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.planos-fragment19 {
  display: contents;
}
.planos-text29 {
  display: inline-block;
}
.planos-fragment20 {
  display: contents;
}
.planos-text31 {
  display: inline-block;
}
.planos-fragment21 {
  display: contents;
}
.planos-text33 {
  display: inline-block;
}
.planos-fragment22 {
  display: contents;
}
.planos-text35 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.planos-fragment23 {
  display: contents;
}
.planos-text37 {
  display: inline-block;
}
.planos-fragment24 {
  display: contents;
}
.planos-text39 {
  display: inline-block;
}
.planos-fragment25 {
  display: contents;
}
.planos-text41 {
  display: inline-block;
  font-stretch: normal;
}
.planos-fragment26 {
  display: contents;
}
.planos-text43 {
  display: inline-block;
}
.planos-fragment27 {
  display: contents;
}
.planos-text45 {
  display: inline-block;
}
.planos-fragment28 {
  display: contents;
}
.planos-text47 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-size: 20px;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.planos-fragment29 {
  display: contents;
}
.planos-text49 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-size: 12px;
  font-style: Bold;
  text-align: left;
  font-family: Inter;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.planos-fragment30 {
  display: contents;
}
.planos-text51 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 24px;
  align-self: center;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.planos-fragment31 {
  display: contents;
}
.planos-text53 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 15px;
  font-style: Semi Bold;
  margin-top: alto;
  text-align: left;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.planos-fragment32 {
  display: contents;
}
.planos-text57 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 24px;
  align-self: center;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.planos-fragment33 {
  display: contents;
}
.planos-text58 {
  color: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 15px;
  font-style: Semi Bold;
  margin-top: alto;
  text-align: left;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.planos-fragment34 {
  display: contents;
}
.planos-text61 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  text-align: center;
  font-stretch: normal;
}
.planos-fragment35 {
  display: contents;
}
.planos-text63 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  text-align: center;
  font-stretch: normal;
}
.planos-fragment36 {
  display: contents;
}
.planos-text65 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  font-stretch: normal;
  text-decoration: none;
}
.planos-fragment37 {
  display: contents;
}
.planos-text67 {
  display: inline-block;
}
.planos-fragment38 {
  display: contents;
}
.planos-text69 {
  display: inline-block;
}
.planos-fragment39 {
  display: contents;
}
.planos-text71 {
  display: inline-block;
}
.planos-fragment40 {
  display: contents;
}
.planos-text72 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.planos-fragment41 {
  display: contents;
}
.planos-text74 {
  display: inline-block;
}
.planos-fragment42 {
  display: contents;
}
.planos-text76 {
  display: inline-block;
}
.planos-fragment43 {
  display: contents;
}
.planos-text78 {
  display: inline-block;
}
.planos-fragment44 {
  display: contents;
}
.planos-text80 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.planos-fragment45 {
  display: contents;
}
.planos-text82 {
  display: inline-block;
}
.planos-fragment46 {
  display: contents;
}
.planos-text84 {
  display: inline-block;
}
.planos-fragment47 {
  display: contents;
}
.planos-text86 {
  display: inline-block;
  font-stretch: normal;
}
.planos-fragment48 {
  display: contents;
}
.planos-text88 {
  display: inline-block;
}
.planos-fragment49 {
  display: contents;
}
.planos-text90 {
  display: inline-block;
}
.planos-fragment50 {
  display: contents;
}
.planos-text92 {
  fill: gray;
  color: gray;
  display: inline-block;
}
.planos-fragment51 {
  display: contents;
}
.planos-text93 {
  fill: gray;
  color: gray;
  display: inline-block;
}
.planos-fragment52 {
  display: contents;
}
.planos-text94 {
  fill: gray;
  color: gray;
  display: inline-block;
}
.planos-container3 {
  display: contents;
}
@media(max-width: 1600px) {
  .planos-text57 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-family: Inter;
    font-weight: 700;
    line-height: 28px;
    text-decoration: none;
  }
  .planos-text58 {
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 15px;
    text-align: center;
    font-weight: 700;
  }
}
@media(max-width: 991px) {
  .planos-body {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .planos-text61 {
    font-size: large;
  }
  .planos-text63 {
    font-size: large;
  }
}
@media(max-width: 479px) {
  .planos-container1 {
    background-color: #D9D9D9;
  }
  .planos-text10 {
    font-style: normal;
  }
  .planos-text12 {
    font-style: normal;
  }
  .planos-text14 {
    font-style: normal;
  }
  .planos-text16 {
    font-style: normal;
  }
  .planos-text43 {
    margin: auto;
  }
  .planos-text57 {
    font-size: small;
    font-weight: 800;
  }
  .planos-text58 {
    font-size: small;
    font-weight: 500;
  }
  .planos-text61 {
    width: auto;
    height: 100%;
  }
  .planos-text90 {
    margin: auto;
  }
}
</style>
