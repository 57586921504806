<template>
  <div class="bx-planos-bx-planos-compra" v-bind:class="rootClassName">
    <div class="bx-planos-frame11">
      <div class="bx-planos-card11 bx_card_compra">
        <div class="card_plano bx-planos-card12">
          <div class="bx-planos-h21">
            <h3>
              <slot name="text21">
                <!--Default content for text21-->
                <div class="bx-planos-fragment10">
                  <h3 class="bx-planos-text24"><span>Gratuito</span></h3>
                </div>
              </slot>
            </h3>
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt15" :src="vectorSrc15" class="icon_plano" />
            <span>
              <slot name="text31">
                <!--Default content for text31-->
                <div class="bx-planos-compra-fragment13">
                  <span class="bx-planos-compra-text31 itens text_plano">
                    <span>Email</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt611" :src="vectorSrc611" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="unionAlt3" :src="unionSrc3" class="icon_plano" />
            <span>
              <slot name="text41">
                <!--Default content for text41-->
                <div class="bx-planos-compra-fragment25">
                  <span class="bx-planos-compra-text55 itens text_plano">
                    <span>WhatsApp</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt21" :src="vectorSrc21" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt31" :src="vectorSrc31" class="icon_plano" />
            <span>
              <slot name="text51">
                <!--Default content for text51-->
                <div class="bx-planos-compra-fragment12">
                  <span class="bx-planos-compra-text30 itens text_plano">
                    Ligação
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt21" :src="vectorSrc21" class="icon_plano" />
          </div>
        </div>
      </div>
      <div class="bx-planos-card21 bx_card_compra">
        <div class="bx-planos-card22 card_plano">
          <div class="bx-planos-h22">
            <h3>
              <slot name="text61">
                <!--Default content for text61-->
                <div class="bx-planos-fragment22">
                  <h3 class="bx-planos-text48"><span>Premium</span></h3>
                </div>
              </slot>
            </h3>
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt15" :src="vectorSrc15" class="icon_plano" />
            <span>
              <slot name="text71">
                <!--Default content for text71-->
                <div class="bx-planos-compra-fragment15">
                  <span class="bx-planos-compra-text35 itens text_plano">
                    <span>Email</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt611" :src="vectorSrc611" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="unionAlt3" :src="unionSrc3" class="icon_plano" />
            <span>
              <slot name="text81">
                <!--Default content for text81-->
                <div class="bx-planos-compra-fragment17">
                  <span class="bx-planos-compra-text39 itens text_plano">
                    <span>WhatsApp</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt611" :src="vectorSrc611" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt31" :src="vectorSrc31" class="icon_plano" />
            <span>
              <slot name="text91">
                <!--Default content for text91-->
                <div class="bx-planos-compra-fragment10">
                  <span class="bx-planos-compra-text26 itens text_plano">
                    <span>Ligação</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt21" :src="vectorSrc21" class="icon_plano" />
          </div>
          <div class="list_plano">
            <span>
              <slot name="text1411">
                <!--Default content for text1411-->
                <div class="bx-planos-fragment19">
                  <span class="bx-planos-text42 text_price">
                    <span>R$ 1,00 / Mês</span>
                  </span>
                </div>
              </slot>
            </span>
          </div>
        </div>
      </div>
      <div class="bx-planos-card31 bx_card_compra">
        <div class="card_plano bx-planos-card32">
          <div class="bx-planos-h23">
            <h3>
              <slot name="text101">
                <!--Default content for text101-->
                <div class="bx-planos-fragment12">
                  <h3 class="bx-planos-text28"><span>Plus</span></h3>
                </div>
              </slot>
            </h3>
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt15" :src="vectorSrc15" class="icon_plano" />
            <span>
              <slot name="text111">
                <!--Default content for text111-->
                <div class="bx-planos-compra-fragment23">
                  <span class="bx-planos-compra-text51 itens text_plano">
                    <span>Email</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt611" :src="vectorSrc611" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="unionAlt3" :src="unionSrc3" class="icon_plano" />
            <span>
              <slot name="text121">
                <!--Default content for text121-->
                <div class="bx-planos-compra-fragment22">
                  <span class="bx-planos-compra-text49 itens text_plano">
                    <span>WhatsApp</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt611" :src="vectorSrc611" class="icon_plano" />
          </div>
          <div class="list_plano">
            <img :alt="vectorAlt31" :src="vectorSrc31" class="icon_plano" />
            <span>
              <slot name="text131">
                <!--Default content for text131-->
                <div class="bx-planos-compra-fragment18">
                  <span class="bx-planos-compra-text41 itens text_plano">
                    <span>Ligação</span>
                  </span>
                </div>
              </slot>
            </span>
            <img :alt="vectorAlt611" :src="vectorSrc611" class="icon_plano" />
          </div>
          <div class="list_plano">
            <span>
              <slot name="text151">
                <!--Default content for text151-->
                <div class="bx-planos-fragment17">
                  <span class="bx-planos-text38 text_price">
                    <span>R$ 50,00 / Mês</span>
                  </span>
                </div>
              </slot>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BxPlanos',
  props: {
    vectorSrc15: {
      type: String,
      default:
        '/static/image/email.svg',
    },
    vectorAlt15: {
      type: String,
      default: 'email',
    },

    vectorSrc31: {
      type: String,
      default:
        '/static/image/telefone.svg',
    },
    vectorAlt31: {
      type: String,
      default: 'telefone',
    },
    unionSrc3: {
      type: String,
      default:
        '/static/image/whtsapp.svg',
    },
    unionAlt3: {
      type: String,
      default: 'whtsapp',
    },    
    vectorSrc21: {
      type: String,
      default:
        '/static/image/x.svg',
    },
    vectorAlt21: {
      type: String,
      default: 'x',
    },
    vectorSrc611: {
      type: String,
      default:
        '/static/image/check.svg',
    },
    vectorAlt611: {
      type: String,
      default: 'check',
    },
  },
}
</script>

<style scoped>
.bx-planos-bx-planos-compra {
  gap: 10px;
  width: 100%;
  height: auto;
  padding: 20px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  flex-direction: column;
  background-color: rgba(117, 174, 187, 0.30000001192092896);
}
.bx-planos-frame11 {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.bx-planos-card11 {
  height: auto;
  margin: auto;
}
.bx-planos-h21 {
  gap: 10px;
  width: 100%;
  height: 25%;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(9, 75, 91, 1);
}
.bx-planos-card21 {
  height: auto;
  margin: auto;
}
.bx-planos-card22 {
  background-color: rgba(71, 137, 152, 1);
}
.bx-planos-h22 {
  gap: 10px;
  width: 100%;
  height: 25%;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(9, 75, 91, 1);
}
.bx-planos-card31 {
  display: none;
  height: auto;
  margin: auto;
}
.bx-planos-h23 {
  gap: 10px;
  width: 100%;
  height: 25%;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 25px;
  justify-content: center;
  background-color: rgba(9, 75, 91, 1);
}
.bx-planos-fragment10 {
  display: contents;
}
.bx-planos-text24 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  font-stretch: normal;
  text-decoration: none;
}
.bx-planos-fragment11 {
  display: contents;
}
.bx-planos-text26 {
  display: inline-block;
}
.bx-planos-fragment12 {
  display: contents;
}
.bx-planos-text28 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.bx-planos-fragment13 {
  display: contents;
}
.bx-planos-text30 {
  display: inline-block;
}
.bx-planos-fragment14 {
  display: contents;
}
.bx-planos-text32 {
  display: inline-block;
}
.bx-planos-fragment15 {
  display: contents;
}
.bx-planos-text34 {
  display: inline-block;
  font-stretch: normal;
}
.bx-planos-fragment16 {
  display: contents;
}
.bx-planos-text36 {
  display: inline-block;
}
.bx-planos-fragment17 {
  display: contents;
}
.bx-planos-text38 {
  display: inline-block;
}
.bx-planos-fragment18 {
  display: contents;
}
.bx-planos-text40 {
  display: inline-block;
}
.bx-planos-fragment19 {
  display: contents;
}
.bx-planos-text42 {
  display: inline-block;
}
.bx-planos-fragment20 {
  display: contents;
}
.bx-planos-text44 {
  display: inline-block;
}
.bx-planos-fragment21 {
  display: contents;
}
.bx-planos-text46 {
  display: inline-block;
}
.bx-planos-fragment22 {
  display: contents;
}
.bx-planos-text48 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  display: inline-block;
  font-style: Semi Bold;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  line-height: 28px;
  font-stretch: normal;
  text-decoration: none;
}
.bx-planos-fragment23 {
  display: contents;
}
.bx-planos-text50 {
  display: inline-block;
}
@media(max-width: 479px) {
  .bx-planos-frame11 {
    height: 100%;
    padding: 10px;
    flex-direction: row;
  }
  .bx-planos-card11 {
    width: auto;
    height: 100%;
  }
  .bx-planos-card12 {
    width: 30vw;
    padding: 10px;
  }
  .bx-planos-card21 {
    width: auto;
    height: 100%;
  }
  .bx-planos-card22 {
    width: 30vw;
    padding: 10px;
  }
  .bx-planos-card31 {
    width: auto;
    height: 100%;
  }
  .bx-planos-card32 {
    width: 30vw;
    padding: 10px;
  }
  .bx-planos-text38 {
    margin: auto;
  }
}
</style>
